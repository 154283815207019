.dyn-map-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
}
.dyn-map-container1 {
  width: 100%;
  height: 48px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.dyn-map-image {
  top: 0px;
  right: 0px;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  position: absolute;
  object-fit: cover;
}
.dyn-map-navlink {
  color: rgb(255, 255, 255);
  text-decoration: none;
  background-color: rgb(0, 0, 0);
}
.dyn-map-navlink1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
  background-color: rgb(0, 0, 0);
}
.dyn-map-navlink2 {
  color: rgb(255, 255, 255);
  text-decoration: none;
  background-color: rgb(0, 0, 0);
}
.dyn-map-navlink3 {
  color: rgb(255, 255, 255);
  text-decoration: none;
  background-color: rgb(0, 0, 0);
}
.dyn-map-text09 {
  text-decoration: none;
}
.dyn-map-iframe {
  width: 1920px;
  height: 942px;
  align-self: center;
}
@media(max-width: 1600px) {
  .dyn-map-container1 {
    height: 57px;
  }
}
@media(max-width: 1200px) {
  .dyn-map-iframe {
    flex: 1;
    width: 100%;
    height: 866px;
    align-self: center;
    margin-top: var(--dl-space-space-threeunits);
  }
}
